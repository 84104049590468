import "../css/PreLoader.css";
import { images } from "../assets";

const PreLoader = () => {
  return (
    <div id="page">
      <div id="container">
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="h3">
          <img
            style={{ width: "50px", height: "50px" }}
            src={images.antaragniLogo}
            alt="antaragniLogo"
          />
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
