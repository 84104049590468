import React from "react";
import "../css/ContactCard.css";
import { images } from "../assets";

const ContactCard = ({ data }) => {
  return (
    <div className="contact-cardm">
      <div className="contact-card">
        <img className="profile-img" src={data.img.url} />
      </div>

      <div className="contact-card2">
        <div className="contact-upper">
          <p className="contact-name">{data.name}</p>
        </div>
        <div className="contact-lower">
          <div className="contact">
            <img className="contact-icon" src={images.email} alt="email-icon" />
            <p className="contact-info">{data.text1}</p>
          </div>
          <div className="contact">
            <img
              className="contact-icon"
              src={images.telephone}
              alt="telephone-icon"
            />
            <p className="contact-info">+91-{data.text2}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
