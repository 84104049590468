import { useState, useRef } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import "../css/Gallery.css";
import gallery from "../assets/Gallery/gallery";

const Gallery = () => {
  const scrollRef = useRef(null);

  const scrollLeftDir = () => scroll("left");
  const scrollRightDir = () => scroll("right");

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (current && current.scrollLeft != null) {
      if (direction === "left") {
        current.scrollLeft -= 800;
      } else if (direction === "right") {
        current.scrollLeft += 500;
      }
    }
  };

  const galleryDB = [
    {
      id: 1,
      img: gallery.g1,
    },
    {
      id: 2,
      img: gallery.g2,
    },
    {
      id: 3,
      img: gallery.g3,
    },
    {
      id: 4,
      img: gallery.g4,
    },
    {
      id: 5,
      img: gallery.g5,
    },
    {
      id: 6,
      img: gallery.g6,
    },
    {
      id: 7,
      img: gallery.g7,
    },
    {
      id: 8,
      img: gallery.g8,
    },
  ];

  const [galleryData] = useState(galleryDB);

  return (
    <div className="app-gallery" id="gallery">
      <div className="app-gallery-content">
        <Fade bottom>
          <h1>Gallery</h1>
        </Fade>
      </div>
      <div className="app-gallery-images">
        <div className="app-gallery-images_container" ref={scrollRef}>
          {galleryData.map((image) => (
            <div className="app-gallery-images_card">
              <img
                key={image.id}
                className="gallery-image"
                src={image.img}
                alt={`img-${image.id}`}
              />
            </div>
          ))}
        </div>
        <div className="app-gallery-images_arrows">
          <div className="gallery-arrows-div">
            <BsArrowLeftShort
              className="gallery-arrow-icon"
              onClick={scrollLeftDir}
            />
          </div>
          <div className="gallery-arrows-div">
            <BsArrowRightShort
              className="gallery-arrow-icon"
              onClick={scrollRightDir}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
