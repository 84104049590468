import junoonBG from "../assets/img/junoonBG.jpg";
import junoonLogo from "../assets/img/junoonLogo.png";
import junoonHomeImg from "../assets/img/junoonHome.jpg";
import antaragniLogo from "../assets/img/antaragniLogo.png";
import fb from "../assets/img/fb.png";
import insta from "../assets/img/insta.png";
import aboutUs from "../assets/img/aboutUs.jpg";
import email from "../assets/Contacts/email.png";
import telephone from "../assets/Contacts/telephone.png";

const images = {
  junoonBG,
  junoonLogo,
  junoonHomeImg,
  antaragniLogo,
  fb,
  insta,
  aboutUs,
  email,
  telephone,
};

export { images };
