import React from "react";
import Fade from "react-awesome-reveal";
import "../css/Guidelines.css";

const Guidelines = () => (
  <div className="guidelines-section" id="guidelines">
    <div className="app__guide-content flex__center">
      <div className="app__guide-content_guidelines">
        <Fade bottom>
          <h1 className="page-title">Guidelines</h1>
          <div className="card">
            <div className="para">
              <p className="p__opensans" id="guide__p">
                ⚙️ To register your band for prelims fill in the details of the
                band.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ In the zone section fill in the zone where you want to
                register for prelims.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You need to upload 2 of your songs on Dropbox/Google Drive
                and fill in the link in the mentioned section. Make sure the
                link can be viewed by anyone who has it.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You can also send us links of your songs on Reverbnation
                instead of Dropbox/Google Drive.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ Please upload the best quality possible as these songs will
                be the short listing criteria for prelims.
              </p>
              <p className="p__opensans" id="guide__p">
                ⚙️ You can also put additional links such as your Youtube links
                in the Other links section. If you have multiple such links,
                send all links separated by a comma.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  </div>
);

export default Guidelines;
