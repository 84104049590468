import "../css/Contact.css";
import { ContactCard } from "../components";

const Contact = ({ data }) => {
  return (
    <div className="contact-us-page" id="contact">
      <div>
        <br />
        <br />
        <h1 className="page-title">Contact Us</h1>
      </div>
      <br />
      <br />
      <br />
      <div className="contactCard-container">
        {data.map((data) => {
          return <ContactCard key={data.Id} data={data} />;
        })}
      </div>
    </div>
  );
};

export default Contact;
