import g1 from "./1.jpg";
import g2 from "./2.jpg";
import g3 from "./3.jpg";
import g4 from "./4.jpg";
import g5 from "./5.jpg";
import g6 from "./6.jpg";
import g7 from "./7.jpg";
import g8 from "./8.jpg";

const gallery = { g1, g2, g3, g4, g5, g6, g7, g8 };

export default gallery;
