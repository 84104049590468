import { Fade } from "react-awesome-reveal";
import "react-vertical-timeline-component/style.min.css";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "../css/Timeline.css";
import { TimelineCard } from "../components";

const Timeline = ({ data }) => {
  return (
    <div
      className="app_schedule app__wrapper section__padding"
      style={{ overflow: "hidden" }}
      id="timeline"
    >
      <Fade bottom>
        <h1 className="page-title">Timeline</h1>
        <br />
      </Fade>
      {data.length <= 0 ? (
        <h1>To be announced...</h1>
      ) : (
        <VerticalTimeline>
          {data.map((event) => (
            <TimelineCard event={event} />
          ))}
        </VerticalTimeline>
      )}
    </div>
  );
};

export default Timeline;
