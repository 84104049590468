import { Fade } from "react-awesome-reveal";
import "../css/Sponsor.css";

const Sponsors = ({ data }) => {
  return (
    <div className="sponsors-section" id="sponsors">
      <Fade bottom>
        <br />
        <h1 className="page-title">Sponsors</h1>
      </Fade>
      <Fade bottom cascade>
        {data.length <= 0 ? (
          <h1>To be announced...</h1>
        ) : (
          <div className="sponsors-container">
            {data.map((sponsor) => {
              return (
                <div className="sponsors-card">
                  <img
                    className="sponsors-img"
                    src={sponsor.img.url}
                    alt={sponsor.name}
                  />
                  <div className="textBox">
                    <p className="sponsorPlace">{sponsor.text1}</p>
                    <p className="sponsorName">{sponsor.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Fade>
      <br/>
    </div>
  );
};

export default Sponsors;
