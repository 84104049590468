import { Zoom } from "react-awesome-reveal";
import { images } from "../assets";
import Socials from "../components/Socials";
import "../css/AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutUs" id="about">
      <br />
      <h1 className="page-title">About Us</h1>

      <div className="container">
        <div className="left">
          <Zoom>
            <div className="img-container">
              <div className="outer-circle">
                <div className="inner-circle">
                  <img
                    className="aboutUs-img"
                    src={images.aboutUs}
                    width="100%"
                    height="100%"
                    alt="Junoon"
                  />
                </div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </Zoom>
          <div className="socials">
            <Zoom>
              <Socials />
            </Zoom>
          </div>
        </div>

        <div className="card">
          <p className="para">
            Rock is not just a genre. It's an experience, it's a culture, and
            more than anything it's a feeling. And to feel is universal. Yet it
            is western rock that dominates the scene. So we bring you Junoon, to
            shine a light and light a fire in the awe-inspiring Eastern Rock
            scene. Feel your heart pounding along with the bass. Turn up the
            volume to the absolute max. Headbang so hard that you feel like
            you're sending waves of seismic proportions out in the universe.
            Rock in its essence is expression, it's disruption, and it's
            revolution. As long as you have a story to tell, rock has your back.
            And we'd like to make your story heard. So we bring you an epic
            battleground for Eastern Rock.
            <br />
            Junoon had reached out to eight major cities in its hunt for the
            country’s best. Bangalore, Mumbai, Chandigarh, Hyderabad, Delhi,
            Pune, Indore and Kolkata. This time around we're back fully offline
            and the winners of the prelims conducted in these cities will get
            the opportunity to go head to head and to win incredible prizes at
            our campus itself! They'll get the opportunity of unparalleled fame
            and prestige of performing for a thousand strong crowd at the finale
            held at IIT Kanpur. If you're ready to tell your story to the world,
            to be louder than the thunder, and to rain down harder than the
            lightning, this is your chance. Sign up!
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
