import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, getDoc, setDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../config";
import "../css/Form.css";

const db = getFirestore(firebaseApp);

const Form = () => {
  const [cities, setCities] = useState([]);
  const fetchData = async () => {
    try {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, "WebContents", "roadtripsCities");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let cities = [];
      for (let i = 0; i < rawData.data.length; i++) {
        if (rawData.data[i].flag === "junoon") {
          cities.push(rawData.data[i].city);
        }
      }
      console.log(cities);
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [bandName, setBandName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [cityName, setCityName] = useState("");
  const [eventName, setEventName] = useState("");
  const [member1, setMember1] = useState("");
  const [member2, setMember2] = useState("");
  const [member3, setMember3] = useState("");
  const [member4, setMember4] = useState("");
  const [member5, setMember5] = useState("");
  const [member6, setMember6] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [submissionLink, setSubmissionLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [otherLink, setOtherLink] = useState("");

  // Handling Input-Change
  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "bandName") {
      setBandName(value);
    }
    if (name === "emailId") {
      setEmailId(value);
    }
    if (name === "cityName") {
      setCityName(value);
    }
    if (name === "eventName") {
      setEventName(value);
    }
    if (name === "member1") {
      setMember1(value);
    }
    if (name === "member2") {
      setMember2(value);
    }
    if (name === "member3") {
      setMember3(value);
    }
    if (name === "member4") {
      setMember4(value);
    }
    if (name === "member5") {
      setMember5(value);
    }
    if (name === "member6") {
      setMember6(value);
    }
    if (name === "contactNumber") {
      setContactNumber(Number(value));
    }
    if (name === "alternateNumber") {
      setAlternateNumber(Number(value));
    }
    if (name === "submissionLink") {
      setSubmissionLink(value);
    }
    if (name === "facebookLink") {
      setFacebookLink(value);
    }
    if (name === "instagramLink") {
      setInstagramLink(value);
    }
    if (name === "otherLink") {
      setOtherLink(value);
    }
  }

  // generating Timestamp-ID
  function generateTimestampId() {
    const timestamp = firebase.firestore.Timestamp.now();
    const timestampString = timestamp.toDate().getTime().toString();
    const cleanedTimestampString = timestampString.replace(/[^a-zA-Z0-9]/g, "");
    return cleanedTimestampString;
  }

  const resetForm = () => {
    setBandName("");
    setAlternateNumber("");
    setCityName("");
    setContactNumber("");
    setEmailId("");
    setFacebookLink("");
    setSubmissionLink("");
    setMember1("");
    setMember2("");
    setMember3("");
    setMember4("");
    setMember5("");
    setMember6("");
    setContactNumber("");
    setInstagramLink("");
    setOtherLink("");
  };

  // Handling Form-Submission
  async function handleFormSubmit(e) {
    e.preventDefault();
    const id = generateTimestampId();
    try {
      await setDoc(doc(db, "junoon24", id), {
        bandName: bandName.toUpperCase(),
        email: emailId,
        city: cityName.toUpperCase(),
        event: eventName.toUpperCase(),
        member1: member1.toUpperCase(),
        member2: member2.toUpperCase(),
        member3: member3.toUpperCase(),
        member4: member4.toUpperCase(),
        member5: member5.toUpperCase(),
        member6: member6.toUpperCase(),
        phone: contactNumber,
        altPhone: alternateNumber,
        submissionLink: submissionLink,
        instaLink: instagramLink,
        fbLink: facebookLink,
        otherLink: otherLink,
      });
      alert("Form Submitted Successfully !!");
      resetForm();
    } catch (err) {
      console.error(err);
      alert("Error occurred while submitting the form !!");
    }
  }

  return (
    <div className="registration-form">
      <form onSubmit={(e) => handleFormSubmit(e)} className="form">
        <div className="inputContainer">
          <div className="containerInput">
            <input
              name="bandName"
              value={bandName}
              onChange={handleInputChange}
              required
              placeholder="Band Name*"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="emailId"
              value={emailId}
              onChange={handleInputChange}
              required
              autoComplete="off"
              placeholder="Email Id*"
              type="email"
            />
          </div>
          {/* <div className="containerInput">
            <input
              name="cityName"
              value={formData.cityName}
              onChange={handleInputChange}
              required
              autoComplete="off"
              placeholder="City Name"
              type="text"
            />
          </div> */}
          <div className="containerInput selectInput">
            <select
              name="cityName"
              id="mySelect"
              value={cityName}
              onChange={handleInputChange}
              required
            >
              <option value="">-- Select City* --</option>
              {cities.map((city) => (
                <option value={city}>{city}</option>
              ))}
            </select>
          </div>
          <div className="containerInput selectInput">
            <select
              name="eventName"
              id="mySelectAk"
              value={eventName}
              onChange={handleInputChange}
              required
            >
              <option value="">-- Select Event* --</option>
              <option value="All India Prelims">All India Prelims</option>
              <option value="Finale">Finale</option>
            </select>
          </div>
          <div className="containerInput">
            <input
              name="member1"
              value={member1}
              onChange={handleInputChange}
              required
              placeholder="Member Name 1*"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="member2"
              value={member2}
              onChange={handleInputChange}
              required
              placeholder="Member Name 2*"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="member3"
              value={member3}
              onChange={handleInputChange}
              placeholder="Member Name 3"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="member4"
              value={member4}
              onChange={handleInputChange}
              placeholder="Member Name 4"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="member5"
              value={member5}
              onChange={handleInputChange}
              placeholder="Member Name 5"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="member6"
              value={member6}
              onChange={handleInputChange}
              placeholder="Member Name 6"
              type="text"
            />
          </div>
          <div className="containerInput">
            <input
              name="contactNumber"
              value={contactNumber}
              onChange={handleInputChange}
              required
              placeholder="Contact No.*"
              type="number"
            />
          </div>
          <div className="containerInput">
            <input
              name="alternateNumber"
              value={alternateNumber}
              onChange={handleInputChange}
              placeholder="Alternate No."
              type="number"
            />
          </div>
          <div className="containerInput">
            <input
              name="submissionLink"
              value={submissionLink}
              onChange={handleInputChange}
              required
              placeholder="Submission Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              name="facebookLink"
              value={facebookLink}
              onChange={handleInputChange}
              required
              placeholder="Facebook Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              name="instagramLink"
              value={instagramLink}
              onChange={handleInputChange}
              required
              placeholder="Instagram Link*"
              type="url"
            />
          </div>
          <div className="containerInput">
            <input
              name="otherLink"
              value={otherLink}
              onChange={handleInputChange}
              placeholder="Other Links"
              type="text"
            />
          </div>
        </div>
        <h2>**If more than 6 members, write comma separated names.</h2>
        <div className="buttonContainer">
          <button className="btn">
            <strong>Submit</strong>
            <div id="container-stars">
              <div id="stars"></div>
            </div>
            <div id="glow">
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
