import { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
import Hamburger from "hamburger-react";
import { images } from "../assets";
import "../css/Navbar.css";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <nav className={colorChange ? "app__navbar colorChange" : "app__navbar"}>
      <div className="app__navbar-logo">
        <img
          onClick={() => window.location.reload()}
          src={images.junoonLogo}
          alt="junoon-logo"
          style={{ cursor: "pointer" }}
        />
      </div>
      <ul className="app__navbar-links">
        <li className="bn">
          <a href="#home">Home</a>
        </li>
        <li className="bn">
          <a href="#about">About</a>
        </li>
        <li className="bn">
          <a href="#timeline">Timeline</a>
        </li>
        <li className="bn">
          <a href="#gallery">Gallery</a>
        </li>
        <li className="bn">
          <a href="#sponsors">Sponsors</a>
        </li>

        <li className="bn">
          <a href="#guidelines">Guidelines</a>
        </li>
        <li className="bn">
          <a href="#register">Register</a>
        </li>
        <li className="bn">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="app__navbar-login">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://antaragni.in/"
          className="p__opensans"
        >
          <img
            className="navbar__social antaragni-logo"
            src={images.antaragniLogo}
            alt="Antaragani"
          />
        </a>
      </div>
      <div className="app__navbar-smallscreen">
        {/* <GiHamburgerMenu
          color="#fff"
          onClick={() => setToggleMenu(true)}
        /> */}
        <Hamburger
          toggled={toggleMenu}
          toggle={setToggleMenu}
          color="whitesmoke"
          fontSize={27}
          duration={0.5}
          rounded
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <button
              content="\u00d7"
              fontSize={27}
              className="navbar__close_btn"
              onClick={() => setToggleMenu(false)}
            >
              Close
            </button>
            <ul className="app__navbar-smallscreen_links">
              {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/junoon_antaragni/"
                  className="p__opensans"
                >
                  <img
                    className="navbar__social"
                    src={images.insta}
                    alt="instagram"
                  />
                </a>
                <h1 style={{ display: "inline" }}>|</h1>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/antaragni.iitk"
                  className="p__opensans"
                >
                  <img
                    className="navbar__social"
                    src={images.fb}
                    alt="facebook"
                  />
                </a>
              </li> */}
              <li className="bn rbn">
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li className="bn rbn">
                <a href="#about" onClick={() => setToggleMenu(false)}>
                  About
                </a>
              </li>
              <li className="bn rbn">
                <a href="#timeline" onClick={() => setToggleMenu(false)}>
                  Timeline
                </a>
              </li>
              <li className="bn rbn">
                <a href="#gallery" onClick={() => setToggleMenu(false)}>
                  Gallery
                </a>
              </li>
              <li className="bn rbn">
                <a href="#sponsors" onClick={() => setToggleMenu(false)}>
                  Sponsors
                </a>
              </li>
              <li className="bn rbn">
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
              <li className="bn rbn">
                <a href="#guidelines" onClick={() => setToggleMenu(false)}>
                  Guidelines
                </a>
              </li>
              <li className="bn rbn">
                <a href="#register" onClick={() => setToggleMenu(false)}>
                  Register
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
