import { useEffect, useState } from "react";
import "./css/App.css";
import { Preloader, Navbar } from "./components";
import { getSingleDoc } from "./config/firebaseFirestore";

import {
  Home,
  AboutUs,
  Timeline,
  Gallery,
  Sponsors,
  Partners,
  Contact,
  Guidelines,
  Register,
} from "./pages";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [partners, setPartners] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const rawData = await getSingleDoc("WebContents", "junoon");
    let contacts = [];
    let schedule = [];
    let sponsors = [];
    let partners= [] ;
    for (let i = 0; i < rawData.data.length; i++) {
      switch (rawData.data[i].flag) {
        case "contact":
          contacts.push(rawData.data[i]);
          break;
        case "sponsor":
          sponsors.push(rawData.data[i]);
          break;
          case "partners":
            partners.push(rawData.data[i]);
          break;
        case "schedule":
          schedule.push(rawData.data[i]);
          break;
        default:
          console.log("Unknown Flag");
          break;
      }
    }
    setContacts(contacts);
    setSchedule(schedule);
    setSponsors(sponsors);
    setPartners(partners);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div className="junoon-app">
          <Navbar />
          <div className="app__bg">
            <Home />
            <AboutUs />
            <Timeline data={schedule} />
            <Gallery />
            <Sponsors data={sponsors} />
            <Partners data={partners}/>
            <Guidelines />
            <Register />
            <Contact data={contacts} />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
